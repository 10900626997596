<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : !disabled ? '修改' : '查看'"
    :close-on-click-modal="false"
    :visible.sync="visible"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      @keyup.enter.native="dataFormSubmit()"
      label-width="80px"
    >
      <el-form-item label="员工姓名" prop="companyEmployees">
        <el-cascader
          v-model="dataForm.companyEmployees"
          :disabled="disabled"
          :options="options"
          :props="{ expandTrigger: 'hover' }"
        >
        </el-cascader>
      </el-form-item>
      <el-form-item label="缺勤日期" required>
        <el-date-picker
          v-model="time"
          class="el-popper DatepickerTime"
          type="daterange"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :disabled="disabled"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <el-radio-group v-model="dataForm.status" :disabled="disabled">
          <el-radio :label="0">请假</el-radio>
          <el-radio :label="1">缺勤</el-radio>
          <el-radio :label="2">出差</el-radio>
          <el-radio :label="3">加班</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input
          v-model="dataForm.remark"
          :disabled="disabled"
          placeholder="备注"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button v-if="!disabled" type="primary" @click="dataFormSubmit()"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { getEmployeesList } from '@/utils/options';

export default {
  data() {
    return {
      disabled: false,
      visible: false,
      dataForm: {
        id: 0,
        companyEmployees: '',
        name: '',
        absenteeismStartDay: '',
        absenteeismEndDay: '',
        status: '',
        remark: '',
      },
      time: [],
      options: [],
      employeesList: [],
      dataRule: {
        companyEmployees: [
          { required: true, message: '员工姓名不能为空', trigger: 'blur' },
        ],
        absenteeismStartDay: [
          { required: true, message: '开始时间不能为空', trigger: 'blur' },
        ],
        absenteeismEndDay: [
          { required: true, message: '结束时间不能为空', trigger: 'blur' },
        ],
        status: [{ required: true, message: '状态不能为空', trigger: 'blur' }],
      },
    };
  },
  created() {
    this.getEmployeesList();
  },
  methods: {
    init(id, disabled) {
      this.time = [];
      this.disabled = disabled;
      this.dataForm.id = id || '';
      this.visible = true;
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields();
        if (this.dataForm.id) {
          this.$http({
            url: `/tc/employeesabsenteeism/info/${this.dataForm.id}`,
            method: 'get',
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.dataForm = { ...data.employeesabsenteeism };
              this.dataForm.companyEmployees = data.employeesabsenteeism
                .companyEmployees
                ? data.employeesabsenteeism.companyEmployees
                    .split(',')
                    .map((item) => parseInt(item))
                : [];
              this.time = [
                data.employeesabsenteeism.absenteeismStartDay,
                data.employeesabsenteeism.absenteeismEndDay,
              ];
            }
          });
        }
      });
    },

    getEmployeesList() {
      getEmployeesList().then(({ data }) => {
        if (data && data.code === 0) {
          this.options = data.option.options;
        }
      });
    },
    // 表单提交
    dataFormSubmit() {
      if (this.time?.length) {
        this.dataForm.absenteeismStartDay = this.time[0];
        this.dataForm.absenteeismEndDay = this.time[1];
      }
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http({
            url: `/tc/employeesabsenteeism/${
              !this.dataForm.id ? 'save' : 'update'
            }`,
            method: 'post',
            data: {
              ...this.dataForm,
              companyEmployees: this.dataForm.companyEmployees
                .map((i) => i)
                .join(','),
            },
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.visible = false;
              this.$emit('refreshDataList');
            }
          });
        }
      });
    },
  },
};
</script>
